<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import test from "@/app.config.json";
  import axios from "axios";

  export default {
    page: {title: "EINSTUDIO-Application-Type",meta: [{name: "description"}]},
    components: {
      Layout,PageHeader
    },data() {
      return {
        title: "APPLICATION TYPE",
        items: [{text: "Home"}, {text: "Learning",active: true}],
        baseUrl:test.baseUrl,user:[],listData:[],name:"",explanation:"",recordId:""
      };
    },created:function(){
      this.user = JSON.parse(localStorage.getItem('user'));
      this.loadData();
    },methods: {
            addData(){
            let loader = this.$loading.show();
            var formToPost = new FormData(document.getElementById("add-group-form"));
            formToPost.append("status",true)
            axios.post(`${this.baseUrl}/Applications/ApplicationType/Add`, formToPost, {
            headers: { Authorization: `Bearer ${this.user.jwt}`}
            })
            .then((response) => 
            {
                loader.hide();
                this.$swal("",response.data.message, "success");
                this.loadData();
                this.hideModal('modal-lg');
            })
            .catch((error) => 
            {
                loader.hide();
                var html="";
                if(error.response.data.length>0)
                {
                for (let index = 0; index < error.response.data.length; index++) 
                {
                    html +="<small>"+ error.response.data[index].message+"</small><hr>";
                }
                }
                else
                {
                html ="<p class='small'>"+ error.response.data.message+"</p>";
                }
                this.$swal("",html,"error");
            });
        },loadData(){
            let loader= this.$loading.show();
            axios.get(this.baseUrl+"/Applications/ApplicationType/GetAll?status=true&pagesize=0&pageindex=0",{
                headers:{
                    Authorization: `Bearer ${this.user.jwt}`,
                }
            }).then((response)=>{
                loader.hide();
                this.listData= response.data;
            }).catch((error)=>{
                loader.hide();
                const html ="<small>"+ error.response.data.message+"</small>";
                this.$swal("",html,"error");
            
            })
        },deleteData(id){
            let loader= this.$loading.show();
            axios.delete(`${this.baseUrl}/Applications/ApplicationType/Delete/`+id, {
                headers: { Authorization: `Bearer ${this.user.jwt}`}
            }).then((response) => 
            {
             loader.hide();
             this.$swal("",response.data.message, "success");
             this.loadData();
            })
            .catch((error) => 
            {
            loader.hide();
            var html ="<p class='small'>"+ error.response.data.message+"</p>";
            this.$swal("",html,"error");
            });
        },editInitialData(id){
            axios.get(this.baseUrl+"/Applications/ApplicationType/GetOne/"+id,{
                headers:{Authorization: `Bearer ${this.user.jwt}`}
            }).then((response)=>{
                this.name=response.data.name;
                this.explanation=response.data.explanation;
                this.recordId=response.data.id;
            }).catch((error)=>{
            const html ="<small>"+ error.response.data.message+"</small>";
            this.$swal("",html,"error");
            })
        },editData(){
            let loader = this.$loading.show();
            var formToPost = new FormData(document.getElementById("edit-group-form"));
            formToPost.append("status",true)
            axios.put(`${this.baseUrl}/Applications/ApplicationType/Set`, formToPost, {
            headers: { Authorization: `Bearer ${this.user.jwt}`}
            })
            .then((response) => 
            {
                loader.hide();
                this.$swal("",response.data.message, "success");
                this.loadData();
                this.hideModal('modal-edit');
            })
            .catch((error) => 
            {
                loader.hide();
                var html="";
                if(error.response.data.length>0)
                {
                for (let index = 0; index < error.response.data.length; index++) 
                {
                    html +="<small>"+ error.response.data[index].message+"</small><hr>";
                }
                }
                else
                {
                html ="<p class='small'>"+ error.response.data.message+"</p>";
                }
                this.$swal("",html,"error");
            });
        },DeleteConfirm(id){
            this.$swal({ 
            title: "WARNING",
            text: "Are you sure? This data will be delete.",
            //type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Yes",
            cancelButtonText:"No",
            }).then((result) => {
                if (result.value) 
                {
                this.deleteData(id)
                }
            });
        },hideModal(modalid) {
        this.$root.$emit('bv::hide::modal',modalid)
      }
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row mb-3" v-if="user.rol=='teacher'">
        <div class="col-lg-12">
            <b-button v-b-modal.modal-lg variant="primary" class="btn btn-success"><i class="fa fa-users"></i> + ADD NEW APPLICATION</b-button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4" v-for="(item, index) in listData" :key="index">
            <div class="card">
                <router-link :to="{ path: 'groupdetails/'+item.id}">
                  
                    <div class="card-body">
                      <div class="d-flex">
                        <div class="flex-1 overflow-hidden" style="width:100%">
                            
                            <p class="text-truncate font-size-14 mb-2"><i class="fa fa-clipboard"></i> {{item.name}}</p>
                            <p class="text-truncate font-size-14 mb-2" v-if="item.explanation!=null && item.explanation!=''">{{item.explanation}}</p>
                        </div>
                    </div>
                </div>
             
                </router-link>
                <div class="card-body border-top py-3">
                    <div class="text-truncate">
                        <a v-b-tooltip.hover title="Edit" type="button" v-b-modal.modal-edit  class="btn btn-sm btn-warning mr-1" @click="editInitialData(item.id)"><i class="fa fa-edit"></i></a>
                        <a v-b-tooltip.hover title="Delete" type="button"  class="btn btn-sm btn-danger" @click="DeleteConfirm(item.id)"><i class="fa fa-trash"></i></a>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <b-modal id="modal-lg" size="lg" title="New Appictaion Type" title-class="font-18" hide-footer>
        <form @submit.prevent="addData()" id="add-group-form">
          
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Application Name (*)</label>
                        <input type="text" class="form-control"  name="name" autocomplete="off"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Explanation</label>
                        <textarea name="explanation"  class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-warning" id="add-button"><i class="fa fa-plus"></i> ADD APPLICATION TYPE</button>
                </div>
            </div>
        </form>
    </b-modal>
    <b-modal id="modal-edit" size="lg" title="Edit Appictaion Type" title-class="font-18" hide-footer>
        <form @submit.prevent="editData()" id="edit-group-form">
            <input type="hidden" name="id" v-model="recordId"/>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Application Name (*)</label>
                        <input type="text" class="form-control" v-model="name" name="name" autocomplete="off"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Explanation</label>
                        <textarea name="explanation" v-model="explanation" class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-warning" id="edit-button"><i class="fa fa-check"></i> SAVE</button>
                </div>
            </div>
        </form>
    </b-modal>
  </Layout>
</template>